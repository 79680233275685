<template>
  <div>
    <Articles v-bind="$props" />
    <section class="managers-council__container">
      <div v-if="about_photo" class="managers-council__container--photo">
        <img
          width="100%"
          height="100%"
          :src="about_photo && about_photo.path"
        />
      </div>
      <div v-else class="managers-council__container--photo">
        <img
          width="100%"
          height="100%"
          :src="mangerMember.main_image && mangerMember.main_image.path"
        />
      </div>
      <b-container>
        <b-row>
          <b-col sm="12" v-if="mainManger">
            <p
              class="main-heading--position pb-4"
              v-text="$t('paragraph.manager_bio')"
            />
            <hr class="h-line my-4" />
            <!-- <hr /> -->
          </b-col>
          <b-col v-for="(item, index) in items" :key="index" sm="12" md="6">
            <h2 class="managers-council__heading" v-html="item.header"></h2>
            <!-- {{ item.header }} -->
            <p class="managers-council__title" v-html="item.text"></p>
            <!-- {{ item.text }} -->
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { IndexData } from "@/helpers/apiMethods";

export default {
  name: "MissionAndVision",
  components: {
    Articles: () => import("@/components/Articles")
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    paraghText: {
      type: String,
      required: false
    },
    mainManger: {
      type: Boolean,
      default: false
    },
    mangerMember: {
      type: Object,
      default: () => {}
    },
    about_photo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.items[0].text = this.settings("about_our_vision");
        this.items[1].text = this.settings("about_our_mission");
      },
      immediate: true
    }
  },

  data() {
    return {
      items: [
        {
          header: this.$t("heading.our_vision"),
          text: ""
        },
        {
          header: this.$t("heading.our_mission"),
          text: ""
        }
      ]
    };
  }
};
</script>
